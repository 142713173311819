import { IconButton, IconButtonPropsColorOverrides, Tooltip } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { MouseEventHandler } from "react";
import { usePrimaryFaded } from "../hooks/common/useColors";

interface ITooltipWithIcon {
  icon: JSX.Element;
  info?: string;
  onClick?: MouseEventHandler<HTMLElement> | undefined;
  disabled?:boolean,
  color?:
    | OverridableStringUnion<
        | "primary"
        | "inherit"
        | "default"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning",
        IconButtonPropsColorOverrides
      >
    | undefined,
  bgColor?:string,
  cursorDefault?:boolean,
  placement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "top-start"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | undefined;
}
export default function TooltipWithIcon(props: ITooltipWithIcon) {
  const color = usePrimaryFaded();

  return (
    <div>
    <Tooltip title={props.info} placement={props.placement}>
      <IconButton
      disabled={props.disabled}
        onClick={props.onClick}
        disableTouchRipple
        color={props.color || "primary"}
        sx={{
          cursor:props.cursorDefault ? "default" : "pointer",
          border: 2,
          borderColor:  color,
          borderRadius: 2,
          backgroundColor: props.bgColor || color,
        }}
      >
        {props.icon}
      </IconButton>
    </Tooltip>
    </div>
  );
}
