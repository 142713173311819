import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Keys } from "../common/Keys";
import { TokenState, TokenTypeEnum } from "../types/TokenStates";


interface IAuthenticationSlice {
  token: string | null;
  status:TokenState,
  tokenType: TokenTypeEnum | null,
  userName?: string | null,
  userBirthday?: string | null,
  location?: string | null,
  allTestsFinished? : boolean
}

const initialState: IAuthenticationSlice = {
  token: localStorage.getItem(Keys.TOKEN),
  status: TokenState.UNKNOWN,
  tokenType: null,
  userName: null,
};

const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState: initialState,
  reducers: {
    setToken: (authenticationSchema, action: PayloadAction<string | null>) => {
      action.payload && localStorage.setItem(Keys.TOKEN, action.payload);
      return {
        ...authenticationSchema,
        token: action.payload,
      };
    },
    setStatus: (authenticationSchema, action: PayloadAction<TokenState>) => {
      return {
        ...authenticationSchema,
        status: action.payload,
      };
    },
    setTokenType: (authenticationSchema, action: PayloadAction<TokenTypeEnum | null>) => {
      return {
        ...authenticationSchema,
        tokenType: action.payload,
      };
    },
    setUsername: (authenticationSchema, action: PayloadAction<string | null | undefined>) => {
      return {
        ...authenticationSchema,
        userName: action.payload,
      };
    },
    setUserBirthday: (authenticationSchema, action: PayloadAction<string | null | undefined>) => {
      return {
        ...authenticationSchema,
        userBirthday: action.payload,
      };
    },
    setLocation: (authenticationSchema, action: PayloadAction<string | null | undefined>) => {
      return {
        ...authenticationSchema,
        location: action.payload,
      };
    },
    setAllTestsFinished: (authenticationSchema, action: PayloadAction<boolean | undefined>) => {
      return {
        ...authenticationSchema,
        allTestsFinished: action.payload,
      };
    },
    setLogOut: () => {
      localStorage.removeItem(Keys.TOKEN);
      return initialState;
    },
  },
});
export const { setToken, setLogOut, setStatus, setTokenType, setUsername, setUserBirthday, setLocation, setAllTestsFinished } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
