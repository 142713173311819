import { createSlice } from "@reduxjs/toolkit";
import StateConsts from "../consts/StateConsts";
import { Keys } from "../common/Keys";

const isToken = () => {
  return localStorage.getItem(Keys.TOKEN) != null;
};
const stateSlice = createSlice({
  name: "stateSchema",
  initialState: {
    // name: StateConsts.CHOOSE_TEST_STATE, //AUTHENTICATION_STATE
    name: isToken()
      ? StateConsts.CHOOSE_TEST_STATE
      : StateConsts.AUTHENTICATION_STATE, //AUTHENTICATION_STATE
  },
  reducers: {
    authenticationState: (stateSchema) => {
      stateSchema.name = StateConsts.AUTHENTICATION_STATE;
    },
    chooseTestState: (stateSchema) => {
      stateSchema.name = StateConsts.CHOOSE_TEST_STATE;
    },
    agreementState: (stateSchema) => {
      stateSchema.name = StateConsts.AGREEMENT_STATE;
    },
    quizState: (stateSchema) => {
      stateSchema.name = StateConsts.QUIZ_STATE;
    },
    showResultState: (stateSchema) => {
      stateSchema.name = StateConsts.SHOW_RESULT_STATE;
    },
    timeoutState: (stateSchema) => {
      stateSchema.name = StateConsts.TIMEOUT_STATE;
    },
    quizRecap: (stateSchema) => {
      stateSchema.name = StateConsts.QUIZ_RECAP;
    },
  },
});
export const {
  authenticationState,
  chooseTestState,
  quizState,
  showResultState,
  timeoutState,
  quizRecap,
  agreementState,
} = stateSlice.actions;
export default stateSlice.reducer;
