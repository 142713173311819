import { useState } from "react";
import { isValid } from "../../services/v1/tokenService";
import { Keys } from "../../common/Keys";
import { IToken } from "../../types/TokenType";
import { IResponse } from "../../types/ResponseType";
import { setTokenType, setUserBirthday, setUsername, setLocation, setAllTestsFinished } from "../../store/authentication";
import { TokenTypeEnum } from "../../types/TokenStates";
import { useDispatch } from "react-redux";

export const useToken = () => {
  const [token, setToken] = useState<IResponse<IToken>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const checkIsValidToken = async (tt: string) => {
    setIsLoading(true);
    setIsError(false);
    try {
      const data = await isValid(tt);
      setToken(data);
      if (data.payload && data.payload.isValid) {
        localStorage.setItem(Keys.TOKEN, data.payload.token);
        dispatch(setTokenType(TokenTypeEnum[data.payload.tokenType]));
        dispatch(setUsername(data.payload.userName))
        dispatch(setUserBirthday(data.payload.userBirthday))
        dispatch(setLocation(data.payload.location))
        dispatch(setAllTestsFinished(data.payload.allTestsFinished))
      }
    } catch (err) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return {
    token,
    isLoading,
    isError,
    checkIsValidToken,
  };
};
