import React, { useEffect, useState } from "react";
import { IconButton, Box, Stack } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import Image from "./Image";
import { IImage } from "../assets/quiz";
import Div from "./Div";
import { useDarkFaded } from "../hooks/common/useColors";
import { IconAttachFile, IconNext, IconPrev } from "../assets/Icons";
import TooltipWithIcon from "./TooltipWithIcon";

interface ICarouselProps {
  images: IImage[];
  onClose: () => void;
}

export default function Carousel({ images, onClose }: ICarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = images.length;
  const background = useDarkFaded();

  const goToNext = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const goToPrev = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  return (
    <Div
      cornered
      contentCentered
      width={"100%"}
      height={"100%"}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: background,
        p: 10,
      }}
      onClick={onClose}
    >
      <Div
        cornered
        sx={{
          overflow: "hidden",
          backgroundColor: "transparent",
          m: 10,
        }}
      >
        <Stack direction={"row"} gap={2}>
          {totalImages > 1 && currentIndex > 0 && (
             <Div contentCentered centered sx={{p:0}}>
             <TooltipWithIcon
               onClick={(e) => goToPrev(e)}
               icon={<IconPrev fontSize="small" />}
               info="Previous attachment"
             />
           </Div>
          )}
          <Image
            onClick={(e) => e.stopPropagation()}
            maxHeight={"100%"}
            maxWidth={"100%"}
            centered
            variant={"contain"}
            link={images[currentIndex].url}
            alt={images[currentIndex].name}
          />
          {totalImages > 1 && currentIndex < totalImages - 1 && (
            <Div contentCentered centered sx={{p:0}}>
              <TooltipWithIcon
                onClick={(e) => goToNext(e)}
                icon={<IconNext fontSize="small" />}
                info="Next attachment"
              />
            </Div>
          )}
        </Stack>
      </Div>
    </Div>
  );
}
