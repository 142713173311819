import { useState } from "react";
import { IResponse } from "../../types/ResponseType";
import { startRegisterUserQuizResponse } from "../../services/v1/quizzesService";
import { IStartQuizResponse } from "../../assets/quiz";
import { useDispatch } from "react-redux";
import { quizState } from "../../store/state";

export const useStartRegisterUserQuizResponse = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<IResponse<IStartQuizResponse>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendRequest = async (userQuizResponse: IStartQuizResponse) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const data = await startRegisterUserQuizResponse(userQuizResponse);
      setData(data);
      if (data.errors.length > 0) {
        setIsError(true);
      } else {
        dispatch(quizState());
      }
    } catch (err) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return {
    data,
    isLoading,
    isError,
    sendRequest,
  };
};
