import {
  Checkbox,
  Container,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useStartRegisterUserQuizResponse } from "../../hooks/query/useStartRegisterUserQuizReeponse";
import { IconNext } from "../../assets/Icons";
import { IStartQuizResponse } from "../../assets/quiz";
import ButtonContained from "../../components/ButtonContained";
import Div from "../../components/Div";
import { quizState } from "../../store/state";
import { useDispatch } from "react-redux";
import { useQuizSelector } from "../../hooks/store/useQuizSelector";
import Section from "../../components/Section";
import Ad from "../../components/Ad";
import { useAuthentiactionSelector } from "../../hooks/store/useAuthenticationSelector";
import { TokenTypeEnum } from "../../types/TokenStates";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import { useLayout } from "../../components/Layout/LayoutContext";

export default function Agreement() {
  const [checkedAgree, setCheckedAgree] = useState(false);
  const [checkedDetails, setCheckedDetails] = useState(false);
  const dispatch = useDispatch();
  const quiz = useQuizSelector().quiz;
  const userName = useAuthentiactionSelector().userName;
  const userBirthday = useAuthentiactionSelector().userBirthday;
  const examinationLocation = useAuthentiactionSelector().location;
  const tokenType = useAuthentiactionSelector().tokenType;

  const { setBottomMiddleSection, resetSections } = useLayout();

  useEffect(() => {
    resetSections()
    setBottomMiddleSection(getInfo());
  }, [checkedAgree, checkedDetails]);

  useEffect(() => {
    TokenTypeEnum.TEST !== tokenType && dispatch(quizState());
  }, [dispatch, tokenType]);

  const {
    isLoading: startTestIsLoading,
    isError: startTestIsError,
    sendRequest,
  } = useStartRegisterUserQuizResponse();

  const handleClick = async () => {
    const startResponse: IStartQuizResponse = {
      startDateTime: new Date(),
      quizId: quiz.id,
    };

    await sendRequest(startResponse);
  };

  const getInfo = () => {
    return (
      <Div
        contentCentered
        sx={{
          flexDirection: "column",
          gap: 2,
          p: 0,
          borderRadius: 0,
        }}
      >
        { startTestIsLoading ? (
          <CircularProgress />
        ) : (
          <ButtonContained
            disabled={!checkedAgree || !checkedDetails}
            onClick={handleClick}
            aftIcon={<IconNext />}
          >
            <Typography variant={"h5"}>Continue</Typography>
          </ButtonContained>
        )}
        {
          startTestIsError && <ErrorSnackbar>please try again later!</ErrorSnackbar>
        }
      </Div>
    );
  };

  return (
    <>
      <Ad title="Read before you go" severity="info" />
      <Section title="Quiz Participant Agreement">
        <Container sx={{ padding: 0}}>
          <Typography variant="body1">
            Your instructor has assigned you to this quiz. By clicking the
            "AGREE" button, you acknowledge and agree to the following terms and
            conditions regarding your participation in quizzes on our platform:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>One Attempt:</strong> You have only one attempt to
                complete the quiz. Make sure to use it wisely.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Time Commencement:</strong> Upon clicking the "AGREE"
                button, the quiz timer will start, and your participation in the
                quiz will be officially registered in our system.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Responsibility for Completion:</strong> It is your
                responsibility to complete the quiz within the allocated time
                frame. Failure to do so may result in your quiz being marked as
                incomplete or failed.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Abandonment Policy:</strong> If you leave or close the
                quiz before completing it, your participation will be marked as
                failed. Ensure that you have sufficient time and a stable
                internet connection to complete the quiz.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Technical Issues:</strong> In the event of any technical
                issues or errors encountered while attempting to solve the quiz,
                please contact your instructor for further clarification and
                assistance.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Compliance:</strong> You agree to comply with all
                instructions provided by the quiz administrator and abide by the
                rules and regulations set forth for the quiz.
              </Typography>
            </li>
          </ul>
          <Typography variant="body1">
            By clicking "AGREE," you confirm that you have read, understood, and
            agree to abide by the terms and conditions outlined in this
            agreement.
          </Typography>
          <Typography mt={4} variant="body1">
            Please verify your details below before proceeding:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>Name:</strong> {userName}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Birthday:</strong> {userBirthday}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Examination center:</strong> {examinationLocation}
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Quiz Selected:</strong> {quiz.name}
              </Typography>
            </li>
          </ul>
          <Stack>
            <Div sx={{ p: 0 }}>
              <Checkbox
                checked={checkedAgree}
                onChange={() => setCheckedAgree(!checkedAgree)}
                inputProps={{ "aria-label": "controlled" }}
              />
              I <strong>agree</strong> to the terms and conditions
            </Div>
            <Div sx={{ p: 0 }}>
              <Checkbox
                checked={checkedDetails}
                onChange={() => setCheckedDetails(!checkedDetails)}
                inputProps={{ "aria-label": "controlled" }}
              />
              I <strong>confirm</strong> that my details are correct
            </Div>
          </Stack>
        </Container>
      </Section>
    </>
  );
}
