export default class DateUtils {
    static formatDate(date: Date, format: string): string {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      
      return format
        .replace('dd', day)
        .replace('MM', month)
        .replace('yyyy', year)
        .replace('yy', year.substr(-2))
        .replace('hh', hours)
        .replace('mm', minutes);
    }
  }