import { useDispatch } from "react-redux";
import { switchToDark, switchToLight } from "../store/theme";
import { IconDarkMode, IconLightMode } from "../assets/Icons";
import { useThemeSelectorIsLight } from "../hooks/store/useThemeSelector";
import { useEffect, useState } from "react";
import TooltipWithIcon from "./TooltipWithIcon";

export default function ThemeSwitcher() {
  const dispatch = useDispatch();
  const isLightTheme = useThemeSelectorIsLight();
  const [checked, setChecked] = useState(isLightTheme);

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    dispatch(checked ? switchToLight() : switchToDark());
  }, [checked, dispatch]);

  return (
    <TooltipWithIcon
      onClick={handleChange}
      icon={
        checked ? (
          <IconDarkMode fontSize="small" />
        ) : (
          <IconLightMode fontSize="small" />
        )
      }
      info={checked ? "Turn off the light" : "Turn on the light"}
    />
  );
}
