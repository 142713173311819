import {
  CircularProgress,
} from "@mui/material";
import { IconFileDownload, IconDownloadDone } from "../../../assets/Icons";
import useDownloadQuizResultsPdf from "../../../hooks/query/useDownloadQuizResultsPdf";
import { useState } from "react";
import TooltipWithIcon from "../../../components/TooltipWithIcon";

interface IDownloadPDF {
  token?: string;
}
export default function DownloadPDF(props: IDownloadPDF) {
  const [isDownloaded, setIsDownloaded] = useState(false);
  const {
    isLoading: isLoadingPDF,
    isError: isErroPDF,
    downloadFile,
  } = useDownloadQuizResultsPdf();

  const handleDownloadResultsPDF = () => {
    downloadFile();
    setIsDownloaded(true);
  };

  return (
    <>
      {isErroPDF ? (
        "Error Downloading the results"
      ) : isLoadingPDF ? (
        <CircularProgress size={35} />
      ) : !isDownloaded ? (
        <TooltipWithIcon
          onClick={handleDownloadResultsPDF}
          icon={<IconFileDownload fontSize="small" />}
          info="Download results"
        />
      ) : (
        <TooltipWithIcon
          disabled
          icon={<IconDownloadDone fontSize="small" />}
          info="File downloaded"
        />
      )}
    </>
  );
}
