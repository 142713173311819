import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Keys } from "../../common/Keys";

export const useAuthentiactionSelector = () => {
  const state = useSelector(
    (state: RootState) => state.entities.authentication
  );
  return state;
};

export const useIsAuthenticate = () => {
  const token = localStorage.getItem(Keys.TOKEN);
  return token != null;
};

export const useTokenType = () => {
  const state = useSelector(
    (state: RootState) => state.entities.authentication
  );
  return state.tokenType;
};

export const useUserName = () => {
  const state = useSelector(
    (state: RootState) => state.entities.authentication
  );
  return state.userName;
};

export const useUserBirthday = () => {
  const state = useSelector(
    (state: RootState) => state.entities.authentication
  );
  return state.userBirthday;
};
