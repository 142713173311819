import { useDispatch, useSelector } from "react-redux";
import { selectQuestions, selectUserAnswers } from "../../../hooks/store/useQuizSelector";
import Div from "../../../components/Div";
import { List } from "@mui/material";
import { IQuestion } from "../../../assets/quiz";
import QuizListItem from "./QuizListItem";
import { useState } from "react";
import { setQuestionNo } from "../../../store/quiz";

interface IQuizList{
    onClick:() => void;
    description?:boolean
}

export default function QuizList(props:IQuizList) {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const questions: IQuestion[] = useSelector(selectQuestions);
  const userAnswers = useSelector(selectUserAnswers);
  const dispatch = useDispatch();

  const setSelectedItem = (itemNo:number) => {
    props.onClick();
    setSelectedQuestion(itemNo);
    dispatch(setQuestionNo(itemNo + 1));
  }

  return (
    <Div sx={{ p: 0, borderRadius: 0, height:"100%" }}>
      <List sx={{ p: 0 }}>
        {questions.map((question, key) => {
          return (
            <QuizListItem
              text={props.description ? question.text : undefined}
              onClick={() => setSelectedItem(key)}
              number={key + 1}
              key={key}
              active={key === selectedQuestion}
              taken={userAnswers.length > key && userAnswers[key] ? true : false}
            />
          );
        })}
      </List>
    </Div>
  );
}