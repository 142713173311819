import { Route, Routes } from "react-router-dom";
import ContentLayout from "./components/Layout/ContentLayout";
import Home from "./pages/home/Home";
import Quizzes from "./pages/quizzes/Quizzes";
import QuizTest from "./pages/QuizTest/QuizTest";
import NotFound from "./pages/notFound/NotFound";
import CreateToken from "./pages/createToken/CreateToken";
import AuthenticationRelatedLayout from "./components/Layout/AuthenticationRelatedLayout";
import Quiz from "./pages/quiz/Quiz";

interface IRouter{
  navHeight: number;
}
export default function Router(props:IRouter) {
  return (
    <>
      <Routes>
        <Route element={<ContentLayout navHeight={props.navHeight}/>}>
          <Route path="/" element={<Home />}></Route>
          {/* <Route path="/all-quizzes" element={<Quiz />}></Route> */}
          <Route path="/quiz" element={<Quizzes />}></Route>
          <Route path="/quiz/:id" element={<QuizTest />}></Route>
        </Route>
        <Route element={<AuthenticationRelatedLayout />}>
          <Route path="/create---token" element={<CreateToken />}></Route>
        </Route>
        {/* 404  PAGE*/}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  );
}
