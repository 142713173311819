import { alpha, Box, Container, Grid, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useLayout } from "./LayoutContext";
import { useIsMobile } from "../../hooks/common/useCommon";
import MobileDrawer from "../MobileDrawer";
import Div from "../Div";
import BottomInfo from "../../pages/quiz/components/BottomInfo";
import { useEffect, useRef, useState } from "react";

interface IContentLayout {
  navHeight: number;
}

export default function ContentLayout(props: IContentLayout) {
  const {
    leftSection,
    rightSection,
    bottomLeftSection,
    bottomMiddleSection,
    bottomRightSection,
  } = useLayout();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const [mainAreaDimention, setMainAreaDimention] = useState(12);
  const [footerHeight, setFooterHeight] = useState(0);
  const bottomInfoRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setMainAreaDimention(12 - (leftSection ? 2 : 0) - (rightSection ? 2 : 0));
  }, [leftSection, rightSection]);

  useEffect(() => {
    if (bottomInfoRef.current) {
      setFooterHeight(bottomInfoRef.current.offsetHeight);
    }
    const handleResize = () => {
      if (bottomInfoRef.current) {
        setFooterHeight(bottomInfoRef.current.offsetHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bottomLeftSection, bottomMiddleSection, bottomRightSection]);

  return (
    <Grid
      container
      sx={{
        height: `calc(100% - ${props.navHeight}px)`,
        flexDirection: "column",
      }}
    >
      <Grid container sx={{ flex: 1 }}>
        <Grid item md={2}>
          {leftSection &&
            (isMobile ? (
              <Div>
                <MobileDrawer fullWidth>{leftSection?.mobile}</MobileDrawer>
              </Div>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  borderRight: "1px solid",
                  borderColor: alpha(theme.palette.text.primary, 0.1),
                  py: 2,
                }}
              >
                <Div
                  centered
                  cornered
                  sx={{ p: 0, maxHeight: "80vh", overflow: "auto" }}
                >
                  {leftSection?.desktop}
                </Div>
              </Box>
            ))}
        </Grid>

        {/* Main Content Area */}
        <Grid item md={mainAreaDimention} xs={12} sx={{ py: 2 }}>
          <Container maxWidth="lg">
            <Outlet />
          </Container>
        </Grid>

        <Grid item md={2}>
          {rightSection && (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                borderLeft: "1px solid",
                borderColor: alpha(theme.palette.text.primary, 0.1),
                py: 2,
              }}
            >
              {rightSection}
            </Box>
          )}
        </Grid>
      </Grid>

      {(bottomLeftSection || bottomRightSection || bottomMiddleSection) && (
        <Box sx={{ height: `${footerHeight}px` }}>
          <BottomInfo
            ref={bottomInfoRef}
            leftChildren={bottomLeftSection}
            middleChildren={bottomMiddleSection}
            rightChildren={bottomRightSection}
          />
        </Box>
      )}
    </Grid>
  );
}
