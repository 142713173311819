import { Keys } from "../../common/Keys";
import axiosInstance from "./axiosInstance";
import { IResponse } from "../../types/ResponseType";
import { IQuiz, IQuizResponse, IStartQuizResponse } from "../../assets/quiz";
import { IQuizObj } from "../../types/quizzesTypes";
import { TokenTypeEnum } from "../../types/TokenStates";
import {
  IQuizDetailsUserRequest,
  IQuizDetailsUserResponse,
} from "../../types/TokenType";
import { AxiosResponse } from "axios";

const token = localStorage.getItem(Keys.TOKEN);
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export const getAllQuizzes = async (tokenType: TokenTypeEnum | null) => {
  const quizzeszBasedOnAuthentication =
    tokenType === TokenTypeEnum.TEST ? "getQuizzesBasedOnAuthentication" : "";
  const { data } = await axiosInstance.get<IResponse<IQuizObj[]>>(
    `/quizzes/${quizzeszBasedOnAuthentication}`
  );
  return data;
};

export const registerUserQuizResponse = async (quizResponse: IQuizResponse) => {
  const { data } = await axiosInstance.post<IResponse<IQuizResponse>>(
    `/quizzes/registerUserQuizResponse`,
    quizResponse
  );
  return data;
};

export const getQuizResultsPdf = async () => {
  try {
    const response = await axiosInstance.post(
      "/quizzes/createQuizResultsPdf",
      {},
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch PDF file");
  }
};

export const startRegisterUserQuizResponse = async (
  startQuizResponse: IStartQuizResponse
) => {
  const { data } = await axiosInstance.post<IResponse<IStartQuizResponse>>(
    `/quizzes/startRegisterUserQuizResponse`,
    startQuizResponse
  );
  return data;
};

export const getQuizUserResponse = async (
  quizDetailsuserRequest: IQuizDetailsUserRequest
) => {
  const { data } = await axiosInstance.post<
    IResponse<IQuizDetailsUserResponse>
  >(`/quizzes/getQuizUserResponse`, quizDetailsuserRequest);
  return data;
};

export const getQuiz = async (id: string) => {
  const { data } = await axiosInstance.get<IResponse<IQuiz>>(
    `/quizzes/${id}/questions`
    // {
    //   params: {
    //     questionLimit: 8,
    //   },
    // }
  );
  return data;
};

export const getQuizAllQuestions = async (id: string) => {
  const { data } = await axiosInstance.get<IResponse<IQuiz>>(
    `quizzes/${id}/summary`
    // {
    //   params: {
    //     questionLimit: 8,
    //   },
    // }
  );
  return data;
};

export const getQuizzesNames = async () => {
  const { data } = await axiosInstance.get<IResponse<string[]>>(
    `quizzes/getQuizzesNames`
  );
  return data;
};
