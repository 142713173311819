import { Snackbar, Alert } from "@mui/material";
import Div from "./Div";
import { ReactElement, useState } from "react";

interface IErrorSnackbar {
  children: ReactElement | string;
  onClose?: () => void;
}
export default function ErrorSnackbar(props: IErrorSnackbar) {
  const [open, setOpen] = useState(true);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    props.onClose && props.onClose();
    setOpen(false);
  };

  return (
    <Div>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%", marginRight: "auto" }}
        >
         Error: {props.children}
        </Alert>
      </Snackbar>
    </Div>
  );
}
