import {alpha, useTheme } from "@mui/material";



export const useDarkFaded  = () => {
    const theme = useTheme();
    const color = alpha(theme.palette.grey[900], 0.7);
    return color;
}

export const usePrimaryFaded  = () => {
    const theme = useTheme();
    const color = alpha(theme.palette.primary.main, 0.03);
    return color;
}