import { useEffect, useState } from "react";
import { getQuizzesNames } from "../../services/v1/quizzesService";
import { IResponse } from "../../types/ResponseType";

export const useQuizzesNames = () => {
  const [data, setData] = useState<IResponse<string[]>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const data = await getQuizzesNames();
        setData(data);
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    })();
  }, []);

  return {
    data,
    isLoading,
    isError,
  };
};
