import { useState } from "react";
import { getQuizResultsPdf } from "../../services/v1/quizzesService";

const useDownloadQuizResultsPdf = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
  
    const downloadFile = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const pdfBlob = await getQuizResultsPdf();
        const fileURL = window.URL.createObjectURL(pdfBlob.data);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", "WWA Test results.pdf");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    return { isLoading, isError, downloadFile };
  };

export default useDownloadQuizResultsPdf;

