import { CircularProgress, Stack, Typography } from "@mui/material";

export default function LoadingFullPage() {
  return (
    <Stack
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        gap: 2
      }}
    >
      <CircularProgress size={50} />
      <Typography variant="body1" color="primary">Loading</Typography>
    </Stack>
  );
}
