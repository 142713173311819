import { alpha, Grid, Typography, useTheme } from "@mui/material";
import Div from "../../../components/Div";
import AnsNo from "./AnsNo";

interface IAnswer {
  number: string;
  label: string;
  index: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
  selected: number | null;
  correctIndex?: number;
  onClick?:() => void;
}

export default function Answer(props: IAnswer) {
  const theme = useTheme();
  const myColor = theme.palette.info.dark;
  const success = theme.palette.success.dark;
  const error = theme.palette.error.dark;

  return (
    <Div
      elevation={1}
      sx={{
        cursor: props.correctIndex === undefined ? "pointer" : "",
        backgroundColor:
          props.index === props.correctIndex
            ? alpha(success, 0.4)
            : props.correctIndex !== undefined && props.index === props.selected
            ? alpha(error, 0.2)
            : props.selected === props.index
            ? alpha(myColor, 0.2)
            : "",

        borderBottom:
          props.index === props.correctIndex
            ? `3px solid ${alpha(success, 0.9)}`
            : props.correctIndex !== undefined && props.index === props.selected
            ? `3px solid ${alpha(error, 0.9)}`
            : props.selected === props.index
            ? `3px solid ${alpha(myColor, 0.9)}`
            : "",
      }}
      onClick={() =>{
        props.onClick && props.onClick();
        props.correctIndex === undefined && props.setSelectedIndex(props.index)
      }
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={2}>
          {" "}
          <AnsNo number={props.number} />
        </Grid>
        <Grid item xs={10} display={"flex"} alignItems={"center"}>
          <Typography variant={"h6"}>{props.label}</Typography>
        </Grid>
      </Grid>
    </Div>
  );
}
