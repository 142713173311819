import { Snackbar, Alert, AlertColor, SnackbarOrigin } from "@mui/material";
import Div from "./Div";
import { ReactElement, useState } from "react";

interface ISnackbar {
  children: ReactElement | string;
  onClose?: () => void;
  severity?: AlertColor;
  anchor?: SnackbarOrigin;
  variant?: 'standard' | 'filled' | 'outlined';
}



export default function SnackbarAlert(props: ISnackbar) {
  const [open, setOpen] = useState(true);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    props.onClose && props.onClose();
    setOpen(false);
  };

  return (
    <Div>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: props.anchor?.horizontal ?? "right", vertical: props.anchor?.vertical ?? "bottom" }}
      >
        <Alert
          onClose={handleClose}
          severity={props.severity ?? 'info'}
          variant={props.variant ?? "filled"}
          sx={{ width: "100%", marginRight: "auto" }}
        >
         {props.severity?? 'Info'}: {props.children}
        </Alert>
      </Snackbar>
    </Div>
  );
}
