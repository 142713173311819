import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect, useState } from "react";
import { IAnswer } from "../../assets/quiz";

export const useQuizSelector = () => {
  const state = useSelector((state: RootState) => state.entities.quiz);
  return state;
};

export const selectQuizInitialState = (state: RootState) => state.entities.quiz;

export const selectCurrentQuestion = (state: RootState) =>
  state.entities.quiz.questions[state.entities.quiz.questionNo - 1];

export const selectUserAnswers = (state: RootState) =>
  state.entities.quiz.userAnswers;

export const selectQuestions = (state: RootState) =>
  state.entities.quiz.questions;

export const selectCurrentQuestionNo = (state: RootState) =>
  state.entities.quiz.questionNo;

export const selectCurrentQuestionUserAnswer = (state: RootState) => {
  const currentIndex = state.entities.quiz.questionNo - 1;
  return state.entities.quiz.userAnswers[currentIndex];
};


export const selectCorrectByUser = (state: RootState) => {
  const { userAnswers } = state.entities.quiz;
  let correctAnswersCount = 0;

  userAnswers.forEach((answer, index: number) => {
    if (answer && answer.isCorrect) {
      correctAnswersCount++;
    }
  });

  return correctAnswersCount;
};

export const selectWrongByUser = (state: RootState) => {
  const { userAnswers } = state.entities.quiz;
  let wrongAnswersCount = 0;

  userAnswers.forEach((answer, index: number) => {
    if (answer && !answer.isCorrect) {
      wrongAnswersCount++;
    }
  });

  return wrongAnswersCount;
};

export const selectQuizName = (state: RootState) =>
  state.entities.quiz.quiz.name;

export const useQuizId = () => {
  const state = useSelector((state: RootState) => state.entities.quiz);
  return state.quiz.id;
};

export const selectQuizTime = (state: RootState) => {
  return state.entities.quiz.time;
};

export const selectTotalQuestions = (state: RootState) =>
  state.entities.quiz.questions.length;

export const useUserLastAnswer = (state: RootState) => {
  const [userAnswer, setUserAnswer] = useState<IAnswer | null>();
  useEffect(() => {
    setUserAnswer(
      state.entities.quiz.userAnswers[
        state.entities.quiz.userAnswers.length - 1
      ]
    );
  }, [state.entities.quiz]);
  return userAnswer;
};