import {
  Button,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IconNext } from "../../assets/Icons";
import Section from "../../components/Section";
import { chooseTestState } from "../../store/state";
import { useToken } from "../../hooks/query/useToken";
import AuthenticationRelatedLayout from "../../components/Layout/AuthenticationRelatedLayout";
import Div from "../../components/Div";
import ErrorSnackbar from "../../components/ErrorSnackbar";

interface IAuthentification {}

export default function Authentification(props: IAuthentification) {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const { token, isLoading, isError, checkIsValidToken } = useToken();

  useEffect(() => {
    if (!isLoading && !isError && token && token.payload?.isValid) {
      dispatch(chooseTestState());
    }
  }, [isLoading, isError, token]);

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    checkIsValidToken(input);
  };
  return (
    <AuthenticationRelatedLayout>
      <Section title="Enter your token first">
        <form onSubmit={handleClick}>
          <Stack gap={4}>
            <TextField
              onChange={changeValue}
              label="eToken"
              type={"password"}
            />
            <Div 
            sx={{ p: 0, width: "100%", borderRadius: 0 }} contentCentered>
              
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  fullWidth
                  size={"large"}
                  variant="contained"
                  color="primary"
                  disabled={isLoading || input.length === 0}
                  type={"submit"}
                >
                  <Typography variant={"h5"}>START</Typography>
                  <IconNext />
                </Button>
              )}
            </Div>
          </Stack>
        </form>
        {isError && (
                <ErrorSnackbar>Token might be expired or invalid</ErrorSnackbar>
              )}
      </Section>
    </AuthenticationRelatedLayout>
  );
}
