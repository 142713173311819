import { useState, FormEvent, useEffect } from "react";
import {
  Stack,
  Typography,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CircularProgress,
} from "@mui/material";
import Div from "../../components/Div";
import { useLogIn } from "../../hooks/query/useLogIn";
import { useTokenCreate } from "../../hooks/query/useTokenCreate";
import { TokenCreate } from "../../types/TokenType";
import { TokenState, TokenTypeEnum } from "../../types/TokenStates";
import TokenModal from "./components/TokenModal";
import Ad from "../../components/Ad";
import TestTokenModal from "./components/TestTokenModal";
import ErrorSnackbar from "../../components/ErrorSnackbar";

export default function CreateToken() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [daysValidity, setDaysValidity] = useState<number>(1);
  const [validFrom, setValidFrom] = useState<Date | null>(null);
  const [validUntil, setValidUntil] = useState<Date | null>(null);
  const [tokenType, setTokenType] = useState(TokenTypeEnum.PRACTICE);
  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [userBirthday, setUserBirthday] = useState<string>("");
  const [subjects, setSubjects] = useState<string>("");

  const {
    data: loginData,
    isLoading: loginLoading,
    isError: loginError,
    logIn,
  } = useLogIn();
  const {
    data: tokenData,
    isLoading: tokenLoading,
    isError: tokenError,
    create: createToken,
  } = useTokenCreate();

  const [shouldLogIn, setShouldLogIn] = useState(false);
  const [shouldCreateToken, setShouldCreateToken] = useState(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isFormValid()) {
      setShouldLogIn(true);
    }
  };

  useEffect(() => {
    if (shouldLogIn && !loginData?.payload?.accessToken) {
      (async () => {
        try {
          await logIn({ email, password });
          setShouldCreateToken(true);
        } catch (error) {}
      })();
      setShouldLogIn(false);
    }
  }, [shouldLogIn, loginData, logIn, email, password]);

  useEffect(() => {
    if (shouldCreateToken && loginData?.payload?.accessToken) {
      const tokenCreateParams: TokenCreate = {
        daysValidity,
        validFrom,
        validUntil,
        userName: name,
        location,
        userBirthday,
        subjects,
        tokenType,
      };

      (async () => {
        try {
          loginData.payload &&
            (await createToken(
              loginData?.payload.accessToken,
              tokenCreateParams
            ));
        } catch (error) {}
      })();
      setShouldCreateToken(false);
    }
  }, [shouldCreateToken, loginData, createToken, daysValidity, validFrom, validUntil, name, location, subjects, tokenType, userBirthday]);

  const isFormValid = () => {
    const practiceModal =
      email.trim().length > 0 &&
      email.includes("@") &&
      email.includes(".") &&
      email.lastIndexOf(".") > email.lastIndexOf("@") &&
      email.lastIndexOf(".") !== email.length - 1 &&
      password.trim().length > 0 &&
      daysValidity > 0;

    let testModal = true;

    if (tokenType === TokenTypeEnum.TEST) {
      testModal =
        name.trim().length > 0 &&
        location.trim().length > 0 &&
        subjects.trim().length > 0 &&
        userBirthday.length > 0 &&
        location.trim().length > 0;
    }
    return practiceModal && testModal;
  };

  const handleTokenStyleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedTokenStyle = event.target.value as TokenTypeEnum;
    setTokenType(selectedTokenStyle);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={3}>
        <Div centered sx={{ backgroundColor: "transparent" }}>
          <Typography variant="h5">Create token</Typography>
        </Div>
        <FormControl>
          <FormLabel>Choose token style</FormLabel>
          <RadioGroup
            row
            defaultValue={TokenTypeEnum.PRACTICE}
            onChange={handleTokenStyleChange}
          >
            <FormControlLabel
              value={TokenTypeEnum.PRACTICE}
              control={<Radio />}
              label={TokenTypeEnum.PRACTICE}
            />
            <FormControlLabel
              value={TokenTypeEnum.TEST}
              control={<Radio />}
              label={TokenTypeEnum.TEST}
            />
          </RadioGroup>
        </FormControl>
        <Stack gap={2}>
          <TextField
            label="Email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            inputProps={{ min: "1" }}
            label="Token validity (days)"
            type="number"
            required
            value={daysValidity}
            onChange={(e) => setDaysValidity(Number(e.target.value))}
          />
          {tokenType === TokenTypeEnum.TEST && (
            <TestTokenModal
              setName={setName}
              setLocation={setLocation}
              setUserBirthday={setUserBirthday}
              setSubjects={setSubjects}
            />
          )}
          <Typography variant="body2" color="info">
            *Validity* represents the duration for which the token remains
            valid.
          </Typography>
        </Stack>
        {loginLoading || tokenLoading ? (
          <Div contentCentered sx={{p:0, borderRadius: 0}}>
            <CircularProgress/>
          </Div>
        ) : tokenData?.payload && loginData?.payload ? (
          <Ad
            title="SUCCESS"
            severity="success"
            desc="Token created successfully!"
          />
        ) : (
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid() || loginLoading || tokenLoading}
          >
            Create token
          </Button>
        )}
        {
          loginError ?
          <ErrorSnackbar>Username or password is incorrect!</ErrorSnackbar>
          :
          tokenError &&
          <ErrorSnackbar>Token wasn't created!</ErrorSnackbar>
        }
      </Stack>
      {tokenData?.payload?.status === TokenState.VALID && (
        <TokenModal
          token={tokenData.payload.token}
          daysValidity={tokenData.payload.daysValidity}
        />
      )}
    </form>
  );
}
