import { Box, Stack, SxProps, Theme, Tooltip, Typography } from "@mui/material";

type IWidget = {
  title?: string;
  subtitle?: string;
  icon?: JSX.Element;
  direction?: "row" | "column";
  sx?: SxProps<Theme> | undefined;
  primaryColor?: string;
  secondaryColor?: string;
  subtitleSize?: number;
  titleSize?: number;
  tooltip?: string;
  wordBreak?: boolean;
};
export default function Widget(props: IWidget) {
  return (
    <Tooltip title={props.tooltip}>
      <Stack
        direction={props?.direction}
        spacing={props?.direction === "row" ? 1 : 0}
        justifyContent={"start"}
        alignItems={"center"}
        sx={{ cursor: props.tooltip && "default", ...props.sx }}
      >
        <Box color={"primary.main"}>{props.icon}</Box>
        <Stack
          justifyContent={"center"}
          alignItems={props?.direction === "row" ? "left" : "center"}
        >
          <Typography
            variant={"h6"}
            fontSize={`${props.titleSize}rem`}
            color={props.primaryColor}
            sx={props.wordBreak ?{ wordBreak: 'break-all'} : {}}
          >
            {props.title}
          </Typography>

          <Typography
            variant={"body1"}
            fontWeight={"bold"}
            fontSize={`${props.subtitleSize}rem`}
            color={props.secondaryColor}
            sx={props.wordBreak ?{ wordBreak: 'break-all'} : {}}
          >
            {props.subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
}
