import { alpha, Box, Container, Grid, useTheme } from "@mui/material";
import React from "react";

export interface IBottomInfo {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  middleChildren?: React.ReactNode;
}

const BottomInfo = React.forwardRef<HTMLDivElement, IBottomInfo>(
  (props, ref) => {
    const theme = useTheme();

    return (
      <Box
        ref={ref}
        sx={{
          bgcolor: "background.paper",
          borderTop: "1px solid",
          borderColor: alpha(theme.palette.text.primary, 0.1),
          padding: 2,
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          overflow:"hidden"
        }}
      >
        <Container
          maxWidth={"lg"}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item>{props.leftChildren}</Grid>
            <Grid
              item
              sx={{
                margin: "auto",
              }}
            >
              {props.middleChildren}
            </Grid>
            <Grid item>{props.rightChildren}</Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
);

export default BottomInfo;
