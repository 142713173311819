import React, { createContext, useContext, useState, ReactNode } from "react";

interface LayoutContextType {
  leftSection?: ResponsiveLayout;
  setLeftSection: (nodes: ResponsiveLayout) => void;
  rightSection?: ReactNode;
  setRightSection: (node: ReactNode) => void;
  bottomLeftSection?: ReactNode;
  setBottomLeftSection: (node: ReactNode) => void;
  bottomRightSection?: ReactNode;
  setBottomRightSection: (node: ReactNode) => void;
  bottomMiddleSection?: ReactNode;
  setBottomMiddleSection: (node: ReactNode) => void;
  resetSections: () => void;
}

export interface ResponsiveLayout {
  mobile: ReactNode,
  desktop: ReactNode,
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const LayoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [leftSection, setLeftSection] = useState<ResponsiveLayout>();
  const [rightSection, setRightSection] = useState<ReactNode>();
  const [bottomLeftSection, setBottomLeftSection] = useState<ReactNode>();
  const [bottomRightSection, setBottomRightSection] = useState<ReactNode>();
  const [bottomMiddleSection, setBottomMiddleSection] = useState<ReactNode>();
  const resetSections = () => {
    setBottomMiddleSection(undefined);
    setLeftSection(undefined);
    setRightSection(undefined);
    setBottomLeftSection(undefined);
    setBottomRightSection(undefined);
  };

  return (
    <LayoutContext.Provider value={{
      leftSection, setLeftSection,
      rightSection, setRightSection,
      bottomLeftSection, setBottomLeftSection,
      bottomRightSection, setBottomRightSection,
      bottomMiddleSection, setBottomMiddleSection,
      resetSections
    }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};
