import React from "react";
import { alpha, Box, Container, Grid, Stack, useTheme } from "@mui/material";
import { IconHome, IconLogout } from "../assets/Icons";
import { useStateSelector } from "../hooks/store/useStateSelector";
import StateConsts from "../consts/StateConsts";
import TopInfo from "../pages/quiz/components/TopInfo";
import ThemeSwitcher from "./ThemeSwitcher";
import UserInfo from "./UserInfo";
import { useTokenType } from "../hooks/store/useAuthenticationSelector";
import { TokenTypeEnum } from "../types/TokenStates";
import NavigationMobile from "./NavigationMobile";
import { useDispatch } from "react-redux";
import { setLogOut } from "../store/authentication";
import { authenticationState } from "../store/state";
import TooltipWithIcon from "./TooltipWithIcon";
import Image from "./Image";
import { useIsMobile } from "../hooks/common/useCommon";

const Navigation = React.forwardRef((props, ref) => {
  const stateSelector = useStateSelector();
  const theme = useTheme();
  const tokenType = useTokenType();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const handleRedirect = () => {
    window.open("https://www.west-wings-aviation.eu", "_blank");
  };

  const handleLogout = () => {
    dispatch(setLogOut());
    dispatch(authenticationState());
  };

  return (
    <Box
      ref={ref}
      sx={{
        borderBottom: "1px solid",
        borderColor: alpha(theme.palette.text.primary, 0.1),
        paddingY: 2,
        zIndex: 1,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth={"xl"}>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          alignContent={"center"}
        >
          <Grid item xs={0}>
            <Image
              width={50}
              link="https://west-wings-aviation.eu/wp-content/uploads/2022/04/west-wings-aviation_LOGO-300x265.png"
            />
          </Grid>

          <Grid item xs={9} md={8}>
            {(stateSelector.name === StateConsts.QUIZ_STATE ||
              stateSelector.name === StateConsts.TIMEOUT_STATE ||
              stateSelector.name === StateConsts.SHOW_RESULT_STATE ||
              stateSelector.name === StateConsts.QUIZ_RECAP) && (
              <TopInfo isMobile={isMobile} />
            )}
          </Grid>

          <Grid
            item
            xs={1}
            md={2}
            justifyContent={"flex-end"}
            alignContent={"center"}
          >
            <Stack direction={"row"} gap={1} justifyContent={"flex-end"}>
              {isMobile ? (
                <NavigationMobile />
              ) : (
                <>
                  <ThemeSwitcher />
                  <TooltipWithIcon
                    onClick={handleRedirect}
                    icon={<IconHome fontSize="small" />}
                    info="Visit our website"
                  />
                  {stateSelector.name !== StateConsts.AUTHENTICATION_STATE &&
                    TokenTypeEnum.TEST !== tokenType && (
                      <TooltipWithIcon
                        color="error"
                        onClick={handleLogout}
                        icon={<IconLogout fontSize="small" />}
                        info="Log out"
                      />
                    )}
                  {TokenTypeEnum.TEST === tokenType && <UserInfo />}
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});

export default Navigation;
