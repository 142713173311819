import {
  useTheme,
  alpha,
  Button,
  ButtonGroup,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import {
  IconDarkMode,
  IconLightMode,
  IconLogout,
  IconWidget,
} from "../assets/Icons";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useState } from "react";
import Div from "./Div";
import { useThemeSelectorIsLight } from "../hooks/store/useThemeSelector";
import { useDispatch } from "react-redux";
import { switchToLight, switchToDark } from "../store/theme";
import {
  useAuthentiactionSelector,
  useUserName,
} from "../hooks/store/useAuthenticationSelector";
import { TokenTypeEnum } from "../types/TokenStates";
import { setLogOut } from "../store/authentication";
import { authenticationState } from "../store/state";
import StateConsts from "../consts/StateConsts";
import { useStateSelector } from "../hooks/store/useStateSelector";
import TooltipWithIcon from "./TooltipWithIcon";

export default function NavigationMobile() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const isLightTheme = useThemeSelectorIsLight();
  const dispatch = useDispatch();
  const theme = useTheme();
  const backgroundColorLightMode = alpha(theme.palette.primary.main, 0.2);
  const tokenType = useAuthentiactionSelector().tokenType;
  const userName = useUserName();
  const stateSelector = useStateSelector();

  const handleLogout = () => {
    dispatch(setLogOut());
    dispatch(authenticationState());
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const changeToDark = () => {
    isLightTheme && dispatch(switchToDark());
  };

  const changeToLight = () => {
    !isLightTheme && dispatch(switchToLight());
  };

  const getAvatar = () => {
    const userNames = userName?.split(" ");
    return userNames && userNames?.length > 1
      ? userNames[0][0] + userNames[1][0]
      : "AA";
  };

  return (
    <>
      <TooltipWithIcon
        onClick={handleDrawerOpen}
        info="More options"
        icon={<IconWidget fontSize="small" />}
      />
      <SwipeableDrawer
        anchor={"bottom"}
        open={openDrawer}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      >
        <Div contentCentered sx={{ borderRadius: 0 }}>
          <>
            <Stack spacing={2} width={"100%"} maxWidth={400}>
              <Div contentCentered>
                <Divider
                  sx={{
                    height: 4,
                    width: 100,
                    backgroundColor: "text.primary",
                    borderRadius: 2,
                  }}
                />
              </Div>
              <Stack spacing={1} width={"100%"}>
                <Typography
                  variant="body2"
                  sx={{ textTransform: "uppercase", fontWeight: "bold" }}
                >
                  Mode
                </Typography>
                <ButtonGroup variant="outlined" fullWidth>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      p: 1,
                      gap: 1,
                      ...(isLightTheme && {
                        backgroundColor: backgroundColorLightMode,
                      }),
                    }}
                    onClick={changeToLight}
                  >
                    <IconLightMode fontSize="small" /> light
                  </Button>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      p: 1,
                      gap: 1,
                      ...(!isLightTheme && {
                        backgroundColor: backgroundColorLightMode,
                      }),
                    }}
                    onClick={changeToDark}
                  >
                    <IconDarkMode fontSize="small" /> dark
                  </Button>
                </ButtonGroup>
              </Stack>
              {TokenTypeEnum.TEST === tokenType && (
                <>
                  <Divider />
                  <Stack spacing={1}>
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "uppercase", fontWeight: "bold" }}
                    >
                      User info
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <TooltipWithIcon
                        cursorDefault
                        icon={
                          <Typography variant="body2" fontWeight={600}>
                            {getAvatar()}
                          </Typography>
                        }
                      />
                      <Typography variant="body1">{userName}</Typography>
                    </Stack>
                  </Stack>
                  <Divider />
                </>
              )}
              {stateSelector.name !== StateConsts.AUTHENTICATION_STATE && (
                <Div
                  onClick={handleLogout}
                  sx={{ p: 0, cursor: "pointer", borderRadius: 0 }}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <IconLogout fontSize="small" sx={{ color: "error.main" }} />
                    <Typography variant="body1" color={"error.main"}>
                      Log out
                    </Typography>
                  </Stack>
                </Div>
              )}
            </Stack>
          </>
        </Div>
      </SwipeableDrawer>
    </>
  );
}
