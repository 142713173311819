import { CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconNext, IconDone } from "../../../assets/Icons";
import { IQuestion, IQuizResponse } from "../../../assets/quiz";
import ButtonContained from "../../../components/ButtonContained";
import QuestionStateConsts from "../../../consts/QuestionStateConsts";
import { useRegisterUserQuizResponse } from "../../../hooks/query/useRegisterUserQuizResponse";
import { useAuthentiactionSelector } from "../../../hooks/store/useAuthenticationSelector";
import { selectQuestionState } from "../../../hooks/store/useQuestionStateSelector";
import {
  selectQuizTime,
  useQuizSelector,
  selectTotalQuestions,
  selectCurrentQuestion,
  selectCurrentQuestionNo,
} from "../../../hooks/store/useQuizSelector";
import { setVerifyState, setNextState } from "../../../store/questionState";
import { incrementQuestionNo, addUserAnswer } from "../../../store/quiz";
import { showResultState } from "../../../store/state";
import { TokenTypeEnum } from "../../../types/TokenStates";
import QuizTestUtil from "../../../utils/QuizTestUtil";
import Countdown from "./Countdown";
import Question from "./Question";
import { useLayout } from "../../../components/Layout/LayoutContext";
import ErrorSnackbar from "../../../components/ErrorSnackbar";

interface IQuizSingleQuestion {
  totalQuestions: number | undefined;
}

export default function QuizSingleQuestion(props: IQuizSingleQuestion) {
  const dispatch = useDispatch();
  const time = useSelector(selectQuizTime);
  const quizSelector = useQuizSelector();
  const noOfQuestions = useSelector(selectTotalQuestions);
  const currentQuestion = useSelector(selectCurrentQuestion);
  const currentQuestionNo = useSelector(selectCurrentQuestionNo);
  const questionState = useSelector(selectQuestionState);
  const tokenType = useAuthentiactionSelector().tokenType;
  const {
    isError: responseIsError,
    isLoading: responseIsLoading,
    sendResponse,
  } = useRegisterUserQuizResponse();

  const [localSelectedIndex, setLocalSelectedIndex] = useState<number | null>(
    null
  );
  const { setBottomLeftSection, setBottomRightSection, resetSections } =
    useLayout();

  useEffect(() => {
    resetSections();
    setBottomLeftSection(getStateButtons());
    setBottomRightSection(getCountdown());
  }, [questionState, currentQuestionNo, localSelectedIndex]);

  const getQuestion = (): IQuestion => {
    if (!currentQuestion) {
      return {
        text: "No question available",
        answers: [],
        images: [],
      };
    }

    return {
      text: currentQuestion.text,
      answers: currentQuestion.answers,
      images: currentQuestion.images,
    };
  };

  const handleNext = () => {
    setLocalSelectedIndex(null);
    dispatch(incrementQuestionNo());
    dispatch(setVerifyState());
  };

  const verifyAnswer = () => {
    dispatch(setNextState());
    registerAnswer();
  };

  const handleFinish = () => {
    if (TokenTypeEnum.TEST === tokenType) {
      sendResponse(getUserQuizResponse());
    } else {
      dispatch(showResultState());
    }
  };

  const getUserQuizResponse = () => {
    const score = QuizTestUtil.getScore(
      quizSelector.userAnswers,
      quizSelector.questions.length
    );
    const userQuizResponse: IQuizResponse = {
      quizResponse: JSON.stringify(quizSelector),
      endDateTime: new Date(),
      promoted: QuizTestUtil.isPromoted(score),
      score: score,
      quizId: quizSelector.quiz.id,
    };

    return userQuizResponse;
  };

  const registerAnswer = () => {
    if (localSelectedIndex !== null) {
      const question = getQuestion();
      dispatch(
        addUserAnswer({
          ...question.answers[localSelectedIndex],
          userSelectedIndex: localSelectedIndex,
        })
      );
    }
  };

  const getStateButtons = () => {
    return (
      <>
        {responseIsError ? (
          <ErrorSnackbar>while trying to save your progress!</ErrorSnackbar>
        ) : responseIsLoading ? (
          <CircularProgress />
        ) : questionState === QuestionStateConsts.VERIFY_STATE ? (
          <ButtonContained
            disabled={localSelectedIndex === null}
            onClick={verifyAnswer}
            aftIcon={<IconNext />}
          >
            <Typography variant={"h5"}>
              {QuestionStateConsts.VERIFY_STATE}
            </Typography>
          </ButtonContained>
        ) : currentQuestionNo === props.totalQuestions ? (
          <ButtonContained
            disabled={localSelectedIndex === null}
            onClick={handleFinish}
            aftIcon={<IconDone />}
          >
            <Typography variant={"h5"}>
              {QuestionStateConsts.FINISH_STATE}
            </Typography>
          </ButtonContained>
        ) : (
          <ButtonContained
            disabled={localSelectedIndex === null}
            onClick={handleNext}
            aftIcon={<IconNext />}
          >
            <Typography variant={"h5"}>
              {QuestionStateConsts.NEXT_STATE}
            </Typography>
          </ButtonContained>
        )}
      </>
    );
  };

  const getCountdown = () => {
    const totalTime = time * noOfQuestions;
    return <Countdown initialSeconds={totalTime} />;
  };

  return (
    <>
      {questionState === QuestionStateConsts.NEXT_STATE ? (
        <Question
          setSelectedIndex={setLocalSelectedIndex}
          question={getQuestion()}
          selectedIndex={localSelectedIndex}
          correctIndex
        />
      ) : (
        <Question
          setSelectedIndex={setLocalSelectedIndex}
          question={getQuestion()}
          selectedIndex={localSelectedIndex}
        />
      )}
    </>
  );
}
