import {
  Checkbox,
  CircularProgress,
  FormControl,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useQuizzesNames } from "../../../hooks/query/useQuizzesNames";
import ErrorSnackbar from "../../../components/ErrorSnackbar";

interface ITestTokenModal {
  setName: React.Dispatch<React.SetStateAction<string>>;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  setUserBirthday: React.Dispatch<React.SetStateAction<string>>;
  setSubjects: React.Dispatch<React.SetStateAction<string>>;
}

export default function TestTokenModal(props: ITestTokenModal) {
  const { data, isLoading, isError } = useQuizzesNames();
  const [fetchedSubjects, setFetchedSubjects] = useState<string[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);

  useEffect(() => {
    if (data?.payload) {
      const subjectsArray = data.payload;
      setFetchedSubjects(subjectsArray);
      props.setSubjects(JSON.stringify(selectedSubjects));
    }
  }, [data?.payload, props, selectedSubjects]);

  useEffect(() => {
    if (data?.payload) {
      setSelectedSubjects(data.payload);
    }
  }, [data?.payload]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setName(event.target.value);
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setLocation(event.target.value);
  };

  const handleUserBirthdayChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setUserBirthday(event.target.value);
  };

  const handleSubjectsChange = (
    event: SelectChangeEvent<string | string[]>
  ) => {
    const {
      target: { value },
    } = event;

    const selectedSubjectsArray = Array.isArray(value) ? value : [value];

    setSelectedSubjects(selectedSubjectsArray);
  };

  return (
    <>
      <Stack gap={2}>
        <TextField
          label="Student name"
          type="text"
          required
          onChange={handleNameChange}
        />
        <TextField
          label="Examination center"
          type="text"
          required
          onChange={handleLocationChange}
        />
        <TextField
          type="date"
          onChange={handleUserBirthdayChange}
          label="Student birthday"
          InputLabelProps={{ shrink: true }}
          required
        />

        {isLoading ? (
          <CircularProgress />
        ) : (
          <FormControl variant="outlined">
            <Select
              multiple
              value={selectedSubjects}
              onChange={handleSubjectsChange}
              renderValue={(selected) => selected.join(", ")}
            >
              {fetchedSubjects.map((subject) => (
                <MenuItem key={subject} value={subject}>
                  <Checkbox checked={selectedSubjects.includes(subject)} />
                  <ListItemText primary={subject} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {
          isError && <ErrorSnackbar>Quizzes couldn't be retrieved! Please try again later.</ErrorSnackbar>
        }
      </Stack>
    </>
  );
}
