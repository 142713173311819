import { Grid } from "@mui/material";
import { IQuestion } from "../../../assets/quiz";
import Div from "../../../components/Div";
import Section from "../../../components/Section";
import Answer from "./Answer";
import ImageComponent from "./ImageComponent";

interface IQuestionComponent {
  question: IQuestion;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
  selectedIndex: null | number;
  correctIndex?: boolean;
  onClick?: () => void;
}

export default function Question(props: IQuestionComponent) {
  const correctIndex = props.question.answers.findIndex(
    (question) => question.isCorrect === true
  );
  const getAnswers = (): JSX.Element[] => {
    return props.question.answers.map((answer, index) => (
      <Answer
        onClick={props.onClick}
        key={index}
        number={String.fromCharCode(65 + index)}
        label={answer.text}
        index={index}
        setSelectedIndex={props.setSelectedIndex}
        correctIndex={props.correctIndex === true ? correctIndex : undefined}
        selected={props.selectedIndex}
      />
    ));
  };

  return (
    <>
      <Section title={props.question.text} centerText>
        <Grid
          direction={"row"}
          container
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} md={6}>
            <Div
              sx={{
                bgcolor: "transparent",
                gap: 4,
                display: "grid",
                paddingX: 0,
              }}
            >
              {props.question.images && props.question.images?.length > 0 && 
                <ImageComponent images={props.question.images} />
              }
              {getAnswers()}
            </Div>
          </Grid>
        </Grid>
      </Section>
    </>
  );
}
