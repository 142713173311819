import { IAnswer } from "../assets/quiz";

export default class QuizTestUtil{
    static getScore(userAnswers:(IAnswer | null)[],  questionsLength: number){
            let good = 0;
        
            userAnswers.forEach((answer) => {
              if (answer && answer.isCorrect) {
                good++;
              }
            });
        
            const score = Math.floor((good / questionsLength) * 100);
            return score;
    }

    static isPromoted(score:number){
        return score >= 75;
    }
}