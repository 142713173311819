export enum TokenState {
    VALID = "VALID",
    EXPIRED = "EXPIRED",
    INVALID = "INVALID",
    UNKNOWN = "UNKNOWN",
    NOT_YET_VALID = "NOT_YET_VALID",
  }

export enum TokenTypeEnum {
    PRACTICE = "PRACTICE",
    TEST = "TEST",
  }