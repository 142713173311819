import { CircularProgress, SxProps, Theme, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { timeoutState } from "../../../store/state";
import { useRegisterUserQuizResponse } from "../../../hooks/query/useRegisterUserQuizResponse";
import QuizTestUtil from "../../../utils/QuizTestUtil";
import { IQuizResponse } from "../../../assets/quiz";
import { useQuizSelector } from "../../../hooks/store/useQuizSelector";
import { TokenTypeEnum } from "../../../types/TokenStates";
import { useAuthentiactionSelector } from "../../../hooks/store/useAuthenticationSelector";
import ErrorSnackbar from "../../../components/ErrorSnackbar";

interface ICountdown {
  initialSeconds: number;
}

export default function Countdown(props: ICountdown) {
  const [seconds, setSeconds] = useState(props.initialSeconds);
  const [pulseColor, setPulseColor] = useState(false);
  const { isError, isLoading, sendResponse } = useRegisterUserQuizResponse();
  const dispatch = useDispatch();
  const quizSelector = useQuizSelector();
  const tokenType = useAuthentiactionSelector().tokenType;

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(countdownInterval);
          if (TokenTypeEnum.TEST === tokenType) {
            sendResponse(getUserQuizResponse());
          } else {
            dispatch(timeoutState());
          }
          return 0;
        }
        return prevSeconds - 1;
      });

      seconds < 5 * 60 && setPulseColor((prevColor) => !prevColor);
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [props.initialSeconds, dispatch, seconds]);

  const getUserQuizResponse = () => {
    const score = QuizTestUtil.getScore(
      quizSelector.userAnswers,
      quizSelector.questions.length
    );
    const userQuizResponse: IQuizResponse = {
      quizResponse: JSON.stringify(quizSelector),
      endDateTime: new Date(),
      promoted: QuizTestUtil.isPromoted(score),
      score: score,
      quizId: quizSelector.quiz.id,
    };

    return userQuizResponse;
  };

  const getTime = () => {
    const minutes = Math.floor(seconds / 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const remainingSeconds = seconds % 60;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const pulseStyle: SxProps<Theme> = {
    color: pulseColor ? "error.main" : "inherit",
    fontWeight: "bold",
  };

  if (isError) {
    return (
      <>
        <ErrorSnackbar >
        Failed to submit your responses ! Check your internet connection.
        </ErrorSnackbar>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <CircularProgress />
      </>
    );
  }

  return (
    <>
      <Typography variant="h5" sx={pulseStyle}>
        {getTime()}
      </Typography>
    </>
  );
}
