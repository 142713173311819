// axiosInstance.ts

import axios from "axios";
// import { toast } from "react-toastify";
import { Keys } from "../../common/Keys";

const axiosInstance = axios.create({
  baseURL: Keys.BASE_URL,
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (
    (localStorage.getItem(Keys.TOKEN) &&
      error.config &&
      error.response &&
      error.response.status === 401) ||
    localStorage.getItem(Keys.TOKEN)?.trim?.length === 0
  ) {
    localStorage.removeItem(Keys.TOKEN);
    document.dispatchEvent(new Event("tokenExpired"));
    return Promise.reject(error);
  }
});
localStorage.getItem(Keys.TOKEN);
export default axiosInstance;
