import ChooseTest from "../chooseTest/ChooseTest";
import Authentification from "../authentification/Authentification";
import ShowResults from "../showResults/ShowResults";
import { useStateSelector } from "../../hooks/store/useStateSelector";
import StateConsts from "../../consts/StateConsts";
import QuizRecap from "../quizRecap/QuizRecap";
import Quiz from "../quiz/Quiz";
import Agreement from "../agreement/Agreement";

export default function Home() {
  const stateSelector = useStateSelector();

  switch (stateSelector.name) {
    case StateConsts.AUTHENTICATION_STATE:
      return <Authentification />;
    case StateConsts.CHOOSE_TEST_STATE:
      return <ChooseTest />;
    case StateConsts.QUIZ_STATE:
      return <Quiz />;
    case StateConsts.AGREEMENT_STATE:
      return <Agreement />;
    case StateConsts.SHOW_RESULT_STATE:
      return <ShowResults />;
    case StateConsts.TIMEOUT_STATE:
      return <ShowResults />;
    case StateConsts.QUIZ_RECAP:
      return <QuizRecap />;
    default:
      return <>An error occurred, please try again later!</>;
  }
}
