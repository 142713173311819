import { CircularProgress, Stack, Typography } from "@mui/material";
import Question from "./Question";
import QuizList from "./QuizList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentQuestion,
  selectCurrentQuestionNo,
  selectQuizTime,
  selectTotalQuestions,
  selectUserAnswers,
  useQuizSelector,
} from "../../../hooks/store/useQuizSelector";
import { IQuestion, IQuizResponse } from "../../../assets/quiz";
import Countdown from "./Countdown";
import { IconNext } from "../../../assets/Icons";
import ButtonContained from "../../../components/ButtonContained";
import QuestionStateConsts from "../../../consts/QuestionStateConsts";
import { addUserAnswer } from "../../../store/quiz";
import QuizTestUtil from "../../../utils/QuizTestUtil";
import { useRegisterUserQuizResponse } from "../../../hooks/query/useRegisterUserQuizResponse";
import {
  ResponsiveLayout,
  useLayout,
} from "../../../components/Layout/LayoutContext";
import Snackbar from "../../../components/Snackbar";
import ErrorSnackbar from "../../../components/ErrorSnackbar";

export default function QuizListQuestion() {
  const [localSelectedIndex, setLocalSelectedIndex] = useState<number | null>(
    null
  );
  const [checkFinishStateModal, setCheckFinishStateModal] = useState(false);
  const dispatch = useDispatch();
  const currentQuestion = useSelector(selectCurrentQuestion);
  const currentQuestionIndex = useSelector(selectCurrentQuestionNo) - 1;
  const time = useSelector(selectQuizTime);
  const noOfQuestions = useSelector(selectTotalQuestions);
  const userAnswers = useSelector(selectUserAnswers);
  const quizSelector = useQuizSelector();
  const {
    isError: responseIsError,
    isLoading: responseIsLoading,
    sendResponse,
  } = useRegisterUserQuizResponse();
  const {
    setLeftSection,
    setBottomLeftSection,
    setBottomRightSection,
    resetSections,
  } = useLayout();

  useEffect(() => {
    resetSections();
    setLeftSection(getLeftSection());
    setBottomLeftSection(getFinishButton());
    setBottomRightSection(getCountdown());
  }, []);

  useEffect(() => {
    if (localSelectedIndex !== null) {
      const question = getQuestion();
      dispatch(
        addUserAnswer({
          ...question.answers[localSelectedIndex],
          userSelectedIndex: localSelectedIndex,
        })
      );
    }
  }, [dispatch, localSelectedIndex]);

  const getLeftSection = (): ResponsiveLayout => {
    return {
      mobile: <QuizList description onClick={resetSelectedQuestion} />,
      desktop: <QuizList description onClick={resetSelectedQuestion} />,
    };
  };

  const getCountdown = () => {
    const totalTime = time * noOfQuestions;
    return <Countdown initialSeconds={totalTime} />;
  };

  const getQuestion = (): IQuestion => {
    if (!currentQuestion) {
      return {
        text: "No question available",
        answers: [],
        images: [],
      };
    }

    return {
      text: currentQuestion.text,
      answers: currentQuestion.answers,
      images: currentQuestion.images,
    };
  };

  const getFinishButton = () => {
    return (
      <ButtonContained onClick={openModal} aftIcon={<IconNext />}>
        <Typography variant={"h5"}>
          {QuestionStateConsts.FINISH_STATE}
        </Typography>
      </ButtonContained>
    );
  };

  const resetSelectedQuestion = () => {
    setLocalSelectedIndex(null);
  };

  const openModal = () => {
    setCheckFinishStateModal(true);
  };

  const handleFinish = () => {
    sendResponse(getUserQuizResponse());
  };

  const getUserQuizResponse = () => {
    const score = QuizTestUtil.getScore(
      quizSelector.userAnswers,
      quizSelector.questions.length
    );
    const userQuizResponse: IQuizResponse = {
      quizResponse: JSON.stringify(quizSelector),
      endDateTime: new Date(),
      promoted: QuizTestUtil.isPromoted(score),
      score: score,
      quizId: quizSelector.quiz.id,
    };

    return userQuizResponse;
  };

  return (
    <>
     {
      responseIsError ? 
        <ErrorSnackbar onClose={() => setCheckFinishStateModal(false)}>
          while trying to save your progress!
        </ErrorSnackbar>
       : responseIsLoading && 
        <CircularProgress />
      
     }
      {checkFinishStateModal &&
        (
          <Snackbar
            onClose={() => setCheckFinishStateModal(false)}
            variant="standard"
            anchor={{ vertical: "bottom", horizontal: "left" }}
          >
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
            >
              Are you sure you want to finish ?
              <ButtonContained onClick={handleFinish} aftIcon={<IconNext />}>
                <Typography variant={"h5"}>{"YES"}</Typography>
              </ButtonContained>
            </Stack>
          </Snackbar>
        )}
      <Question
        setSelectedIndex={setLocalSelectedIndex}
        question={getQuestion()}
        selectedIndex={
          userAnswers[currentQuestionIndex] !== null
            ? userAnswers[currentQuestionIndex]?.userSelectedIndex ??
              localSelectedIndex
            : localSelectedIndex
        }
      />
    </>
  );
}
