import {
  AlertColor,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconNext } from "../../assets/Icons";
import Ad from "../../components/Ad";
import Div from "../../components/Div";
import Section from "../../components/Section";
import { setQuizNameAndId } from "../../store/quiz";
import { agreementState } from "../../store/state";
import { useAllQuizzes } from "../../hooks/query/useAllQuizzes";
import { useToken } from "../../hooks/query/useToken";
import { Keys } from "../../common/Keys";
import ButtonContained from "../../components/ButtonContained";
import { useQuizUserResponse } from "../../hooks/query/useQuizUserResponse";
import { IQuizDetailsUserRequest } from "../../types/TokenType";
import { useAuthentiactionSelector } from "../../hooks/store/useAuthenticationSelector";
import DownloadPDF from "./components/DownloadPDF";
import LoadingFullPage from "../../components/LoadingFullPage";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import { useLayout } from "../../components/Layout/LayoutContext";

interface IChooseTest {}
export default function ChooseTest(props: IChooseTest) {
  const dispatch = useDispatch();
  const [colorSeverity, setColorSeverity] = useState<AlertColor>();
  const [isDetailsQuiz, setIsDetailsQuiz] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [info, setInfo] = useState("Please select an option to continue!");
  const [tokenInfo, setTokenInfo] = useState("");
  const theme = useTheme();
  const color = theme.palette.text.primary;
  const selectColor = alpha(color, 0.05);
  const localStorageToken = localStorage.getItem(Keys.TOKEN);
  const areAllTestsFinished = useAuthentiactionSelector().allTestsFinished;
  const { data: quizzes, isLoading, isError } = useAllQuizzes();
  const { setBottomMiddleSection, resetSections } = useLayout();
  const {
    token,
    isLoading: tokenIsLoading,
    isError: tokenIsError,
    checkIsValidToken,
  } = useToken();
  const {
    isError: quizUserResponseIsError,
    isLoading: quizUserResponseIsLoading,
    sendRequest,
  } = useQuizUserResponse();

  useEffect(() => {
    resetSections()
    setBottomMiddleSection(getInfo());
  }, [info]);

  useEffect(() => {
    localStorageToken && checkIsValidToken(localStorageToken);
  }, []);

  useEffect(() => {
    quizzes?.payload &&
      selectedIndex !== null &&
      setInfo(`You selected ${quizzes.payload[selectedIndex].name}`);
  }, [selectedIndex]);

  useEffect(() => {
    const expirationDate = token?.payload?.validUntil
      ? new Date(token?.payload?.validUntil)
      : new Date(token?.payload?.expiresAt || "");
    const now = new Date();
    const tokenInfo = timeBetween(now, expirationDate);
    setTokenInfo(`Your token will expire in ${tokenInfo}.`);
  }, [token]);

  const timeBetween = (startDate: Date, endDate: Date) => {
    const difference = endDate.getTime() - startDate.getTime();
    const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesDifference = Math.floor(
      (difference % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (daysDifference > 0) {
      setColorSeverity("info");
      return `${daysDifference} day${daysDifference === 1 ? "" : "s"}`;
    } else if (hoursDifference > 0) {
      setColorSeverity("warning");
      return `${hoursDifference} hour${hoursDifference === 1 ? "" : "s"}`;
    } else if (minutesDifference > 0) {
      setColorSeverity("error");
      return `${minutesDifference} minute${minutesDifference === 1 ? "" : "s"}`;
    } else {
      setColorSeverity("error");
      return `less than 1 minute`;
    }
  };

  const handleSelectQuiz = (
    index: number,
    promoted: boolean | null | undefined
  ) => {
    if (!canTest(promoted)) {
      setIsDetailsQuiz(true);
    } else {
      setIsDetailsQuiz(false);
    }
    setSelectedIndex(index);
  };

  const handleClick = () => {
    if (quizzes?.payload && selectedIndex !== null) {
      if (isDetailsQuiz) {
        const request: IQuizDetailsUserRequest = {
          quizId: quizzes?.payload[selectedIndex].id,
        };
        sendRequest(request);
      } else {
        dispatch(
          setQuizNameAndId({
            id: quizzes.payload[selectedIndex].id,
            name: quizzes.payload[selectedIndex].name,
          })
        );
        dispatch(agreementState());
      }
    }
  };

  const getInfo = () => {
    return (
      <Div
        contentCentered
        sx={{
          flexDirection: "column",
          gap: 2,
          p: 0,
          borderRadius: 0,
        }}
      >
        <Typography variant={"h6"}>{info}</Typography>

        {quizUserResponseIsError ? (
          <ErrorSnackbar>
            We couldn't get any details for this test!
          </ErrorSnackbar>
        ) : quizUserResponseIsLoading ? (
          <CircularProgress />
        ) : (
          <ButtonContained
            disabled={selectedIndex === null}
            onClick={handleClick}
            aftIcon={<IconNext />}
          >
            <Typography variant={"h5"}>
              {isDetailsQuiz ? "DETAILS" : "START"}
            </Typography>
          </ButtonContained>
        )}
      </Div>
    );
  };

  const canTest = (promoted: boolean | null | undefined) => {
    return promoted === undefined || promoted === null;
  };

  const isIncomplete = (
    promoted: boolean | null | undefined,
    score: number | null | undefined
  ) => {
    return score === null && promoted === false;
  };

  const getTextForScore = (
    promoted: boolean | null | undefined,
    score: number | null | undefined
  ) => {
    if (score !== null && score !== undefined) {
      return `${score} %`;
    }
    if (promoted === false) {
      return "Incomplete";
    }
    return null;
  };

  if (isError) {
    return <ErrorSnackbar>retrieving quizzes!</ErrorSnackbar>;
  }
  if (isLoading) {
    return <LoadingFullPage />;
  }
  return (
    <>
      {!tokenIsError && !tokenIsLoading && token && (
        <Ad title={tokenInfo} severity={colorSeverity} close />
      )}
      <Section centerText title="Choose your test:">
        <Container sx={{ padding: 0}}>
          {areAllTestsFinished && (
            <Div sx={{ display: "flex", justifyContent: "flex-end" }}>
              <DownloadPDF />
            </Div>
          )}
          <Stack spacing={2} sx={{ paddingX: 0 }}>
            {quizzes?.payload &&
              quizzes.payload.map((materie, index) => (
                <Div
                  onClick={() =>
                    !isIncomplete(materie.promoted, materie.score) &&
                    handleSelectQuiz(index, materie.promoted)
                  }
                  key={index}
                  sx={{
                    backgroundColor: selectedIndex === index ? selectColor : "",
                    cursor: isIncomplete(materie.promoted, materie.score)
                      ? "default"
                      : "pointer",
                    opacity: isIncomplete(materie.promoted, materie.score)
                      ? 0.4
                      : 1,
                    color:
                      materie.promoted === true
                        ? "success.main"
                        : materie.promoted === false
                        ? "error.main"
                        : "",
                  }}
                >
                  <Grid
                    width={"100%"}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignContent={"space-between"}
                    alignItems={"space-between"}
                    container
                  >
                    <Grid
                      xs={
                        getTextForScore(materie.promoted, materie.score)
                          ? 10
                          : 12
                      }
                      item
                    >
                      <Typography fontWeight={"bold"}>
                        {materie.name}
                      </Typography>
                    </Grid>
                    <Grid xs={2} item>
                      <Typography>
                        {getTextForScore(materie.promoted, materie.score)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Div>
              ))}
          </Stack>
        </Container>
      </Section>
    </>
  );
}