import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAnswer, IQuestion } from "../assets/quiz";

interface IQuizNameId {
  id: string;
  name: string;
}
export interface IQuizSlice {
  quiz: IQuizNameId;
  questions: IQuestion[];
  questionNo: number;
  userAnswers: (IAnswer | null)[];
  time: number;
}

const initialState: IQuizSlice = {
  quiz: { id: "", name: "" },
  questions: [],
  questionNo: 1,
  userAnswers: [],
  time: 0,
};

const quizSlice = createSlice({
  name: "quizSchema",
  initialState,
  reducers: {
    setQuizNameAndId: (quizSchema, action) => {
      return {
        ...quizSchema,
        quiz: action.payload,
      };
    },
    setQuizTime: (quizSchema, action) => {
      return {
        ...quizSchema,
        time: action.payload,
      };
    },
    setQuestions: (quizSchema, action: PayloadAction<IQuestion[]>) => {
      return {
        ...quizSchema,
        questions: action.payload,
      };
    },

    incrementQuestionNo: (quizSchema) => {
      return {
        ...quizSchema,
        questionNo: quizSchema.questionNo + 1,
      };
    },

    decrementQuestionNo: (quizSchema) => {
      return {
        ...quizSchema,
        questionNo: quizSchema.questionNo - 1,
      };
    },
    setQuestionNo: (quizSchema, action) => {
      return {
        ...quizSchema,
        questionNo: action.payload,
      };
    },

    addUserAnswer: (state, action) => {
      const updatedUserAnswers = [...state.userAnswers];
      updatedUserAnswers[state.questionNo - 1] = action.payload;
      state.userAnswers = updatedUserAnswers;
    },
    setQuizSlice: (quizSchema, action) => {
      quizSchema = action.payload;
      quizSchema.userAnswers.fill(null, 0, quizSchema.questions.length);
      return quizSchema;
    },
    resetQuiz: () => initialState,
  },
});

export const {
  setQuizNameAndId,
  setQuestions,
  incrementQuestionNo,
  decrementQuestionNo,
  addUserAnswer,
  resetQuiz,
  setQuizTime,
  setQuestionNo,
  setQuizSlice,
} = quizSlice.actions;
export default quizSlice.reducer;
