import { useState } from "react";
import { IResponse } from "../../types/ResponseType";
import { registerUserQuizResponse } from "../../services/v1/quizzesService";
import { IQuizResponse } from "../../assets/quiz";
import { useDispatch } from "react-redux";
import { showResultState } from "../../store/state";

export const useRegisterUserQuizResponse = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<IResponse<IQuizResponse>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const sendResponse = async (userQuizResponse:IQuizResponse) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const data = await registerUserQuizResponse(userQuizResponse);
      setData(data);
      if(data.errors.length > 0){
        setIsError(true);
      } else {
        dispatch(showResultState());
      }
    } catch (err) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return {
    data,
    isLoading,
    isError,
    sendResponse,
  };
};
