import { SxProps, Theme } from "@mui/material";

import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import RoofingIcon from "@mui/icons-material/Roofing";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import WidgetsIcon from '@mui/icons-material/Widgets';
import ContrastIcon from '@mui/icons-material/Contrast';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import LogoutIcon from '@mui/icons-material/Logout';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import PhotoIcon from '@mui/icons-material/Photo';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ListIcon from '@mui/icons-material/List';

type IIcon = {
  fontSize?: "small" | "inherit" | "large" | "medium" | undefined;
  sx?: SxProps<Theme> | undefined;
};

export const IconContentPaste = (props: IIcon) => {
  return <ContentPasteIcon {...props} />;
};

export const IconLightMode = (props: IIcon) => {
  return <LightModeIcon {...props} />;
};

export const IconContentCopy = (props: IIcon) => {
  return <ContentCopyIcon {...props} />;
};

export const IconDarkMode = (props: IIcon) => {
  return <DarkModeIcon {...props} />;
};

export const IconNext = (props: IIcon) => {
  return <NavigateNextIcon {...props} />;
};
export const IconPrev = (props: IIcon) => {
  return <NavigateBeforeIcon {...props} />;
};

export const IconDone = (props: IIcon) => {
  return <DoneIcon {...props} />;
};

export const IconClose = (props: IIcon) => {
  return <CloseIcon {...props} />;
};

export const IconHome = (props: IIcon) => {
  return <RoofingIcon {...props} />;
};

export const IconWidget = (props: IIcon) => {
  return <WidgetsIcon {...props} />;
};

export const IconContrast = (props: IIcon) => {
  return <ContrastIcon {...props} />;
};

export const IconHorizontalRule = (props: IIcon) => {
  return <HorizontalRuleIcon {...props} />;
};

export const IconSmartPhone = (props: IIcon) => {
  return <SmartphoneIcon {...props} />;
};

export const IconLogout = (props: IIcon) => {
  return <LogoutIcon {...props} />;
};

export const IconMoreHorizon = (props: IIcon) => {
  return <MoreHorizIcon {...props} />;
};

export const IconFileDownload = (props: IIcon) => {
  return <FileDownloadIcon {...props} />;
};

export const IconDownloadDone = (props: IIcon) => {
  return <DownloadDoneIcon {...props} />;
};

export const IconPhoto = (props: IIcon) => {
  return <PhotoIcon {...props} />;
};

export const IconAttachFile = (props: IIcon) => {
  return <AttachFileIcon {...props} />;
};

export const IconList = (props: IIcon) => {
  return <ListIcon {...props} />;
};