import { useState } from "react";
import { useDispatch } from "react-redux";
import { IResponse } from "../../types/ResponseType";
import { getQuizUserResponse } from "../../services/v1/quizzesService";
import { IQuizDetailsUserRequest, IQuizDetailsUserResponse } from "../../types/TokenType";
import { showResultState } from "../../store/state";
import { IQuizSlice, setQuizSlice } from "../../store/quiz";

export const useQuizUserResponse = () => {
  const [data, setData] = useState<IResponse<IQuizDetailsUserResponse>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const sendRequest = async (quizDetailsUserRequest: IQuizDetailsUserRequest) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const data = await getQuizUserResponse(quizDetailsUserRequest);
      setData(data);
      if (data.payload && data.payload.response) {
        const quizSlice: IQuizSlice = JSON.parse(data.payload.response);
        dispatch(setQuizSlice(quizSlice));
        dispatch(showResultState());
      }
      if (data.errors.length > 0) {
        setIsError(true);
      }
    } catch (err) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return {
    data,
    isLoading,
    isError,
    sendRequest
  };
};
