import {
    Stack,
    Divider,
    Box,
  } from "@mui/material";
  import {
    IconList,
  } from "../assets/Icons";
  import SwipeableDrawer from "@mui/material/SwipeableDrawer";
  import { ReactNode, useState } from "react";
  import Div from "./Div";
  import TooltipWithIcon from "./TooltipWithIcon";
  
  interface IMobileDrawer{
    children:ReactNode;
    fullWidth?:boolean;
  }

  export default function MobileDrawer(props:IMobileDrawer) {
    const [openDrawer, setOpenDrawer] = useState(false);
  
    const handleDrawerOpen = () => {
      setOpenDrawer(true);
    };
  
    const handleDrawerClose = () => {
      setOpenDrawer(false);
    };
    return (
      <>
        <TooltipWithIcon
          onClick={handleDrawerOpen}
          info="See questions"
          icon={<IconList fontSize="small" />}
        />
        <SwipeableDrawer
          anchor={"bottom"}
          open={openDrawer}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        >
          <Div contentCentered sx={{ borderRadius: 0, p: props.fullWidth ? 0 : 2 }} >
            <>
              <Stack spacing={2} width={"100%"} maxWidth={props.fullWidth ? "100%" : 400} >
                <Div contentCentered>
                  <Divider
                    sx={{
                      height: 4,
                      width: 100,
                      backgroundColor: "text.primary",
                      borderRadius: 2,
                    }}
                  />
                </Div>
                <Box sx={{maxHeight:"80vh", overflowY:"auto"}}>
                {props.children}
                </Box>
            </Stack>
            </>
          </Div>
        </SwipeableDrawer>
      </>
    );
  }
  