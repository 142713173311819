import ErrorSnackbar from "../../components/ErrorSnackbar";
import LoadingFullPage from "../../components/LoadingFullPage";
import { useQuiz } from "../../hooks/query/useQuiz";
import { useTokenType } from "../../hooks/store/useAuthenticationSelector";
import { useQuizId } from "../../hooks/store/useQuizSelector";
import { TokenTypeEnum } from "../../types/TokenStates";
import QuizListQuestion from "./components/QuizListQuestion";
import QuizSingleQuestion from "./components/QuizSingleQuestion";

interface IQuiz {}

export default function Quiz(props: IQuiz) {
  const id = useQuizId();
  const tokenType = useTokenType();
  const { data: quiz, isLoading, isError } = useQuiz(id);
  const totalQuestions = quiz?.payload?.questions && quiz?.payload?.questions.length;
  const isList = TokenTypeEnum.TEST === tokenType;


  if (isError) {
    return <ErrorSnackbar>retrieving the quiz!</ErrorSnackbar>;
  }

  if (isLoading) {
    return <LoadingFullPage />;
  }

  if (!quiz?.payload) {
    return <>No data</>;
  }

  return isList ? <QuizListQuestion /> : <QuizSingleQuestion totalQuestions={totalQuestions}/>;
}
