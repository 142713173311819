import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import Navigation from "./components/Navigation";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useThemeSelector } from "./hooks/store/useThemeSelector";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { dark, light } from "./themes/CustomTheme";
import TokenExpired from "./pages/tokenExpired/TokenExpired";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { setStatus } from "./store/authentication";
import { TokenState } from "./types/TokenStates";
import { LayoutProvider } from "./components/Layout/LayoutContext";

function App() {
  const queryClient = new QueryClient();
  const dispatch = useDispatch();
  const [navHeight, setNavHeight] = useState(0);
  const navRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleTokenExpired = () => {
      dispatch(setStatus(TokenState.EXPIRED));
    };

    document.addEventListener("tokenExpired", handleTokenExpired);

    return () => {
      document.removeEventListener("tokenExpired", handleTokenExpired);
    };
  }, [dispatch]);

  useEffect(() => {
    if (navRef.current) {
      setNavHeight(navRef.current.offsetHeight);
    }
    const handleResize = () => {
      if (navRef.current) {
        setNavHeight(navRef.current.offsetHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <LayoutProvider>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={useThemeSelector().light ? light : dark}>
          <CssBaseline />
          <Navigation ref={navRef} />
          <Router navHeight={navHeight} />
          <TokenExpired/>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
    </LayoutProvider>

  );
}

export default App;
