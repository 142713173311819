import { useAuthentiactionSelector } from "../../hooks/store/useAuthenticationSelector";
import { TokenState } from "../../types/TokenStates";
import TokenExpiredComponent from "./components/TokenExpiredComponents";

export default function TokenExpired () {
  const tokenStatus = useAuthentiactionSelector().status;

  if (tokenStatus === TokenState.EXPIRED) {
    return <TokenExpiredComponent />;
  }
  return <></>;
}
