import { Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuizSelector } from "../../hooks/store/useQuizSelector";
import { resetQuiz, setQuestionNo } from "../../store/quiz";
import { chooseTestState, quizRecap } from "../../store/state";
import Div from "../../components/Div";
import { setVerifyState } from "../../store/questionState";
import { useStateSelector } from "../../hooks/store/useStateSelector";
import StateConsts from "../../consts/StateConsts";
import ButtonContained from "../../components/ButtonContained";
import DateUtils from "../../utils/DateUtils";
import {
  useAuthentiactionSelector,
  useUserName,
} from "../../hooks/store/useAuthenticationSelector";
import QuizTestUtil from "../../utils/QuizTestUtil";
import { TokenTypeEnum } from "../../types/TokenStates";
import { useLayout } from "../../components/Layout/LayoutContext";

interface IShowResults {}

export default function ShowResults(props: IShowResults) {
  const dispatch = useDispatch();
  const userAnswers = useQuizSelector().userAnswers;
  const userName = useUserName();
  const questionsLength = useQuizSelector().questions.length;
  const stateSelector = useStateSelector();
  const score = QuizTestUtil.getScore(userAnswers, questionsLength);
  const promoted = QuizTestUtil.isPromoted(score);
  const tokenType = useAuthentiactionSelector().tokenType;
  const { setBottomLeftSection, setBottomRightSection, resetSections } = useLayout();

  useEffect(() => {
    resetSections();
    setBottomLeftSection(getRecapButton());
    setBottomRightSection(getNewQuizButton());
  }, []);

  useEffect(() => {
    dispatch(setQuestionNo(userAnswers.length));
  }, [dispatch, userAnswers.length]);

  const handleClick = () => {
    dispatch(setVerifyState());
    dispatch(chooseTestState());
    dispatch(resetQuiz());
  };

  const recapQuiz = () => {
    dispatch(quizRecap());
  };

  const getMessage = () => {
    if (score === 100) {
      return "Outstanding!";
    }
    if (score >= 90) {
      return "You nailed it!";
    }
    if (score >= 80) {
      return "Excellent work!";
    }
    if (score >= 75) {
      return "You did it!";
    }

    return "Better luck next time!";
  };

  const getNewQuizButton = () => {
    return (
      <ButtonContained onClick={handleClick}>
        <Typography variant={"h5"}>New Quiz</Typography>
      </ButtonContained>
    );
  };

  const getRecapButton = () => {
    return (
      <ButtonContained onClick={recapQuiz}>
        <Typography variant={"h5"}>See results</Typography>
      </ButtonContained>
    );
  };

  return (
    <>
      {TokenTypeEnum.TEST === tokenType && (
        <Grid
          container
          spacing={2}
          mb={10}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography variant="h5">Name: {userName}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              Date: {DateUtils.formatDate(new Date(), "dd/MM/yyyy")}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={6}>
          {stateSelector.name === StateConsts.TIMEOUT_STATE && (
            <Typography variant="h5">Your time has expired!</Typography>
          )}
          <Div
            sx={{
              bgcolor: "transparent",
              display: "grid",
              paddingX: 0,
            }}
          >
            <Typography
              color={promoted ? "success.main" : "error.main"}
              variant={"h4"}
            >
              {getMessage()}
            </Typography>
            <Typography
              color={promoted ? "success.main" : "error.main"}
              variant={"h4"}
            >
              You scored {score}%
            </Typography>
            <LinearProgress
              color={promoted ? "success" : "error"}
              variant="determinate"
              value={score}
            />
          </Div>
        </Grid>
      </Grid>
    </>
  );
}
