import {
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useUserName } from "../hooks/store/useAuthenticationSelector";
import { IconLogout } from "../assets/Icons";
import { setLogOut } from "../store/authentication";
import { useDispatch } from "react-redux";
import { authenticationState } from "../store/state";
import TooltipWithIcon from "./TooltipWithIcon";

export default function UserInfo() {
  const userName = useUserName();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const getAvatar = () => {
    const userNames = userName?.split(" ");
    return userNames && userNames?.length > 1
      ? (userNames[0][0] + userNames[1][0]).toUpperCase()
      : "AA";
  };

  const handleLogout = () => {
    dispatch(setLogOut());
    dispatch(authenticationState());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TooltipWithIcon
        onClick={handleClick}
        icon={
          <Typography variant="body2" fontWeight={600}>
            {getAvatar()}
          </Typography>
        }
        info="User info"
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>{userName}</MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} sx={{ color: "error.main", gap: 1 }}>
          <IconLogout fontSize="small" />
          Log out
        </MenuItem>
      </Menu>
    </>
  );
}
