import { useState } from "react";
import { IconAttachFile } from "../../../assets/Icons";
import { IImage } from "../../../assets/quiz";
import TooltipWithIcon from "../../../components/TooltipWithIcon";
import Carousel from "../../../components/Carousel";
import { Stack, Typography, useTheme } from "@mui/material";

interface IImageComponent {
  images: IImage[];
}
export default function ImageComponent(props: IImageComponent) {
  const [isCarouselOpen, setCarouselOpen] = useState(false);
  const theme = useTheme();

  const openCarousel = () => {
    setCarouselOpen(true);
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };
  return (
    <Stack
      direction={"row"}
      gap={1}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <TooltipWithIcon
        onClick={openCarousel}
        icon={<IconAttachFile fontSize="small" />}
        info="View Attachment"
      />
      {isCarouselOpen && (
        <Carousel images={props.images} onClose={closeCarousel} />
      )}

      <Typography fontWeight={"bold"} color={theme.palette.primary.main}>
        {props.images.length} x attachment{props.images.length > 1 && "s"}
      </Typography>
    </Stack>
  );
}
