import { ListItemButton, ListItemText, Stack, Typography } from "@mui/material";

interface IQuizListItem {
  number: number;
  taken: boolean;
  active: boolean;
  onClick: () => void;
  text?: string;
}
export default function QuizListItem(props: IQuizListItem) {
  return (
    <>
      <ListItemButton selected={props.active} onClick={props.onClick}>
        <ListItemText
          primary={
            <Stack direction={"row"} spacing={3}>
              <Typography
                sx={{
                  color: props.taken ? "primary.main" : "default",
                  fontWeight: props.taken ? "600" : "default",
                }}
              >
                {props.number}
              </Typography>
              <Typography
                noWrap
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: props.taken ? "primary.main" : "default",
                  fontWeight: props.taken ? "600" : "default",
                }}
              >
                {props.text}
              </Typography>
            </Stack>
          }
        />
      </ListItemButton>
    </>
  );
}
